import React from "react";
import styles from "./NavBar.module.scss";
import { useNavigate } from "react-router-dom";
import { getSidebarOptions } from "../../services/sidebarOptions";
import { useSelector } from "react-redux";

const NavBar = () => {
  const navigate = useNavigate();

  const profiles = useSelector((state) => state.agent.profiles);
  const profileStatus = useSelector((state) => state.agent.profileStatus);
  const contractStatus = useSelector((state) => state.contract.contractStatus);

  const navbarOptions = getSidebarOptions(
    profiles,
    profileStatus,
    contractStatus
  );

  const handleNavbarNavigation = (option) => {
    switch (option) {
      case "Profile":
        navigate("/");
        break;
      case "Contracts":
        navigate("/contracts");
        break;
      case "Requests":
        navigate("/requests");
        break;
      default:
        return;
    }
  };

  return (
    <div className={styles.navbar}>
      {navbarOptions.map((item, index) => (
        <button
          key={index}
          className={`${styles.item} ${item.disabled ? styles.disabled : ""}`}
          onClick={() => handleNavbarNavigation(item.label)}
          disabled={item.disabled}
        >
          {item.icon}
          {item.text}
        </button>
      ))}
    </div>
  );
};

export default NavBar;
