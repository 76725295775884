import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requests: [],
  request: "",
  requestStatus: null,
  currencies: [],
};

export const requestSlice = createSlice({
  name: "request",
  initialState,
  reducers: {
    setRequests: (state, action) => {
      state.requests = action.payload;
    },
    setCurrencies: (state, action) => {
      state.currencies = action.payload;
    },
    removeRequests: (state, action) => {
      state.requests = [];
    },
    setRequest: (state, action) => {
      state.request = action.payload;
    },
    removeRequest: (state, action) => {
      state.request = "";
    },
    addRequests: (state, action) => {
      state.requests = [...state.requests, action.payload];
    },
    updateRequests: (state, action) => {
      state.requests = state.requests.map((item) =>
        item._id === action.payload._id ? action.payload : item
      );
    },
    setRequestsStatus: (state, action) => {
      state.requestStatus = action.payload;
    },
    removeRequestsStatus: (state, action) => {
      state.requestStatus = null;
    },
  },
});

export const { setRequests, setCurrencies } = requestSlice.actions;

export default requestSlice.reducer;
