import axios from "axios";
import { baseURL } from "../config.js";

const path = "/agent_requests";

const instance = axios.create({
  baseURL: baseURL + path,
  headers: {},
});

export const requestAPI = {
  async get_request(authData, contractId, req = null) {
    const { access } = authData;

    const url = req
      ? `/get_request/${contractId}/?req=${req}`
      : `/get_request/${contractId}/`;

    try {
      const response = await instance.get(url, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error getting requests:",
        err.response ? err.response.data : err
      );
      console.log("Failed to get requests. Check console for details.");
    }
  },
  async get__currency(authData) {
    const { access } = authData;

    try {
      const response = await instance.get(`/get__currency/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error getting currency:",
        err.response ? err.response.data : err
      );
      console.log("Failed to get currency. Check console for details.");
    }
  },
};
