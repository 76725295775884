import RequestsTable from "../../components/RequestsTable/RequestsTable";
import styles from "./RequestsPage.module.scss";
import { useContext, useEffect, useState } from "react";
import Request from "../../components/Request/Request";
import MainModal from "../../components/MainModal/MainModal";
import { IoMdClose } from "react-icons/io";
import { Button, Divider } from "@mui/material";
import NewInfoModal from "../../components/NewInfoModal/NewInfoModal";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { requestAPI } from "../../api/requestAPI";
import { setCurrencies, setRequests } from "../../redux/slices/requestSlice";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ModalsContext from "../../contexts/ModalsContext";

const headers = [
  { name: "Номер заявки", key: "id", type: "Text" },
  { name: "Дата заявки", key: "requestDate", type: "Text" },
  {
    name: "Статус заявки",
    key: "requestStatus",
    type: "Chip",
  },
  { name: "Сумма", key: "total", type: "TextFieldNumber" },
  { name: "Валюта", key: "currency", type: "Select" },
  { name: "Согласованный курс", key: "agreedRate", type: "Text" },
];

const rows = [
  {
    id: 1,
    requestDate: "28.07.2024",
    requestStatus: "Счет на оплату",
    total: 100000,
    currency: "USD",
    agreedRate: "89",
  },
  {
    id: 2,
    requestDate: "28.07.2024",
    requestStatus: "Заявка подписана",
    total: 3000,
    currency: "EUR",
    agreedRate: "89",
  },
  {
    id: 3,
    requestDate: "28.07.2024",
    requestStatus: "Курс согласован",
    total: 2000,
    currency: "USD",
    agreedRate: "89",
  },
  {
    id: 4,
    requestDate: "28.07.2024",
    requestStatus: "Запрос курса",
    total: 430943,
    currency: "USD",
    agreedRate: "89",
  },
  {
    id: 5,
    requestDate: "28.07.2024",
    requestStatus: "Счет на оплату",
    total: 24938298,
    currency: "RUB",
    agreedRate: "89",
  },
  {
    id: 6,
    requestDate: "28.07.2024",
    requestStatus: "Заявка подписана",
    total: 1000,
    currency: "CNY",
    agreedRate: "89",
  },
  {
    id: 7,
    requestDate: "28.07.2024",
    requestStatus: "Счет на оплату",
    total: 49999,
    currency: "EUR",
    agreedRate: "89",
  },
  {
    id: 8,
    requestDate: "28.07.2024",
    requestStatus: "Счет на оплату",
    total: 49999,
    currency: "EUR",
    agreedRate: "89",
  },
  {
    id: 9,
    requestDate: "28.07.2024",
    requestStatus: "Счет на оплату",
    total: 49999,
    currency: "EUR",
    agreedRate: "89",
  },
  {
    id: 10,
    requestDate: "28.07.2024",
    requestStatus: "Счет на оплату",
    total: 49999,
    currency: "EUR",
    agreedRate: "89",
  },
];

const files = [
  {
    name: "ДТ1_Шаблон_заявки.pdfДТ1_Шаблон_заявки.pdfДТ1_Шаблон_заявки.pdfДТ1_Шаблон_заявки.pdfДТ1_Шаблон_заявки.pdfДТ1_Шаблон_заявки.pdf",
    type: "pdf",
  },
  { name: "ВБК_Саратов_Москва (3).pdf", type: "pdf" },
  { name: "ДТ1_Шаблон_заявки.doc", type: "word" },
  { name: "ДТ1_Шаблон_заявки.xlsx", type: "excel" },
  { name: "Шаблон_заявки.jpg", type: "image" },
  { name: "Агентский_отчет.txt", type: "unknown" },
];

const newRequestOptions = [
  {
    label: "Контракт",
    type: "Text",
    name: "contract_name",
    text: "839848239489834849849'",
  },
  {
    label: "Статус заявки",
    type: "Chip",
    text: "Запрос курса",
    name: "request_status",
    statusColor: "Green",
  },
  // {
  //   label: "Сумма",
  //   type: "TextField",
  //   text: "Введите сумму",
  //   name: "total",
  // },
  {
    label: "Валюта",
    type: "Select",
    text: "RUB",
    name: "currency_pay",
  },
  {
    label: "Комментарий клиента",
    type: "TextField",
    text: "Введите свой комментарий",
    name: "comment_client",
  },
];

const RequestsPage = () => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);

  const [newRequestData, setNewRequestData] = useState({
    request_status: 1,
    number_contract: "",
    comment_client: "",
    currency_pay: null,
  });

  const contract = useSelector((state) => state.contract.contract);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const authData = useSelector((state) => state.auth.authData);
  const requests = useSelector((state) => state.request.requests);
  const currencies = useSelector((state) => state.request.currencies);

  const dispatch = useDispatch();

  const {
    handleRequesttModalOpen,
    handleRequestModalClose,
    newRequestModalOpen,
  } = useContext(ModalsContext);

  useEffect(() => {
    console.log("isAuth", isAuth);
    setLoading(true);
    if (isAuth && contract) {
      (async () => {
        try {
          // fetching requests
          const response = await requestAPI.get_request(authData, contract.id);
          if (response.statusText === "OK") {
            const requestsData = response.data;
            dispatch(setRequests(requestsData));
            if (requestsData.length === 0) {
              handleRequesttModalOpen();
            }
          }
          // fetching currencies
          const dataCurrency = await requestAPI.get__currency(authData);
          if (dataCurrency.statusText === "OK") {
            console.log("currencies", dataCurrency.data);

            const currencies = dataCurrency.data;
            dispatch(setCurrencies(currencies));
          }
          setLoading(false);
        } catch (err) {
          console.log(err);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, contract]);

  // useEffect(() => {
  //   console.log("currencies", currencies);
  // }, currencies);

  const handleChangeInputs = (event, field) => {
    let value = event.target.value;

    console.log("newRequestData", newRequestData);
    console.log("value", value);

    setNewRequestData({
      ...newRequestData,
      [field]: value,
    });
  };

  const handleRowClick = (row) => {
    setSelectedRow(row);
  };

  return (
    <div className={styles.container}>
      {!selectedRow && (
        <div className={styles.headerText}>
          <div className={styles.text}>Плательщик</div>
          <div className={styles.payer}>{contract.agent_title}</div>
          <div className={styles.text}>Контракт</div>
          <div className={styles.payer}>{contract.contract_number}</div>
        </div>
      )}

      <div className={styles.buttonContainer}>
        {selectedRow && (
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            sx={{
              // minWidth: "140px",
              minHeight: "40px",
            }}
            onClick={() => setSelectedRow(null)}
          >
            Вернуться к заявкам
          </Button>
        )}
        <Button
          variant="contained"
          endIcon={<AddIcon />}
          sx={{
            // minWidth: "140px",
            minHeight: "40px",
          }}
          onClick={handleRequesttModalOpen}
        >
          Новая заявка
        </Button>
      </div>

      {!selectedRow && requests.length > 0 ? (
        <RequestsTable
          headers={headers}
          contract={contract}
          payer={contract.agent_title}
          rows={rows}
          handleRowClick={handleRowClick}
        />
      ) : (
        <div className={styles.warnText}>
          <CurrencyExchangeIcon className={styles.rotatingIcon} />
          <span>Добавьте новую заявку</span>
        </div>
      )}

      {selectedRow && (
        <Request
          text="Заявка"
          headers={headers}
          item={selectedRow}
          setSelectedRow={setSelectedRow}
          files={files}
          contract={contract}
          handleOpen={handleRequesttModalOpen}
        />
      )}

      <MainModal open={newRequestModalOpen} onClose={handleRequestModalClose}>
        <div className={styles.modalheader}>
          <h2>Новая заявка</h2>
          <div onClick={handleRequestModalClose}>
            <IoMdClose size={25} />
          </div>
        </div>
        <Divider />
        <NewInfoModal
          options={newRequestOptions}
          handleChangeInputs={handleChangeInputs}
          currencies={currencies}
          newData={newRequestData}
        />
      </MainModal>
    </div>
  );
};

export default RequestsPage;
