import styles from "./Request.module.scss";
import React, { useEffect } from "react";
import ReusableChip from "../../components/ReusableChip/ReusableChip";
import { MenuItem, Select, TextField } from "@mui/material";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { GrAttachment } from "react-icons/gr";
import { fileIcons } from "../reusables/fileIcons";

const currencies = [
  "USD",
  "EUR",
  "RUB",
  "GBP",
  "CNY",
  "KZT",
  "TRY",
  "INR",
  "JPY",
  "AED",
];

const Request = ({ item, text, files, headers, contract }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChangeCurrency = (e) => {
    console.log(e.target.value);
  };

  const renderField = (header) => {
    const value = item[header.key];

    switch (header.type) {
      case "Chip":
        return (
          <ReusableChip
            borderColor="rgba(76, 175, 80, 0.5)"
            backgroundColor="rgba(76, 175, 80, 0.1)"
            color="rgba(28, 107, 31, 0.8)"
            label={value}
            minHeight="45px"
            minWidth="100px"
          />
        );

      case "Select":
        return (
          <Select value={value || ""} onChange={(e) => handleChangeCurrency(e)}>
            {currencies.map((currency, index) => (
              <MenuItem key={index} value={currency}>
                {currency}
              </MenuItem>
            ))}
          </Select>
        );

      case "TextFieldNumber":
        return (
          <TextField
            defaultValue={value}
            type="number"
            sx={{
              width: "100%",
              "& .MuiInputLabel-root": {
                color: "#757575",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#757575",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#757575",
                },
              },
            }}
          />
        );

      case "Text":
      default:
        return <div className={styles.field}>{value}</div>;
    }
  };
  return (
    <div className={styles.container}>
      <h2>{text}</h2>
      <>
        <div className={styles.element}>
          <div className={styles.name}>
            <span>Контракт </span>
          </div>
          <div className={styles.field}>{contract}</div>
        </div>
        <div className={styles.line} />
      </>

      {headers.map((header, index) => (
        <React.Fragment key={index}>
          <div className={styles.element}>
            <div className={styles.name}>
              <span>{header.name}</span>
            </div>
            <div className={styles.field}>{renderField(header)}</div>
          </div>
          <div className={styles.line} />
        </React.Fragment>
      ))}
      <>
        <div className={styles.element}>
          <div className={styles.name}>
            <span>Комментарий менеджера </span>
          </div>
          <div className={styles.field}>Комментарий менеджера</div>
        </div>
        <div className={styles.line} />
      </>
      <>
        <div className={styles.element}>
          <div className={styles.name}>
            <span>Комментарий клиента </span>
          </div>
          <div className={styles.field}>
            <TextField
              label="Комментарий клиента"
              sx={{
                width: "100%",
              }}
            />
          </div>
        </div>
        <div className={styles.line} />
      </>
      <>
        <div className={styles.element}>
          <div className={styles.name}>
            <span>Инвойс от поставщика </span>
          </div>
          <div className={styles.field}>
            <TextField
              label="Инвойс от поставщика"
              sx={{
                width: "100%",
              }}
            />
          </div>
        </div>
        <div className={styles.line} />
      </>
      <>
        <div className={styles.element}>
          <div className={styles.name}>
            <span>Файлы </span>
          </div>
          <div className={styles.filesContainer}>
            {files.map((item, index) => (
              <div className={styles.card} key={index}>
                <div className={styles.cardContent}>
                  <div className={styles.icon}>{fileIcons[item.type]}</div>
                  <span className={styles.text}>{item.name}</span>
                </div>
                <button className={styles.downloadButton}>
                  <FaCloudDownloadAlt size={20} />
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.line} />
      </>
      <>
        <div className={styles.element}>
          <div className={styles.name}>
            <span>Добавить файлы </span>
          </div>
          <div className={styles.field}>
            <button className={styles.downloadButton}>
              <span>Добавить файлы</span>
              <GrAttachment size={20} />
            </button>
          </div>
        </div>
        <div className={styles.line} />
      </>
    </div>
  );
};

export default Request;
