import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import agentReducer from "./slices/agentSlice";
import contractReducer from "./slices/contractSlice";
import requestReducer from "./slices/requestSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  agent: agentReducer,
  contract: contractReducer,
  request: requestReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: {
        ignoredPaths: [],
      },
      serializableCheck: {},
    }),
});
