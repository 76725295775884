import React from "react";
import styles from "./InfoComponent.module.scss";
import { TextField } from "@mui/material";
import ReusableChip from "../ReusableChip/ReusableChip";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { GrAttachment } from "react-icons/gr";
import DotsSpinner from "../Loaders/DotsSpinner/DotsSpinner";
import { fileIcons } from "../reusables/fileIcons";

const InfoComponent = ({
  options,
  text,
  contractStatus,
  company,
  contract,
  handleChangeComment,
  clientComment,
  triggerFileInput,
  handleFileUpload,
  fileLoading,
}) => {
  const getFileIcon = (fileType) => {
    if (fileType.includes("pdf")) return fileIcons.pdf;
    if (
      fileType.includes("msword") ||
      fileType.includes("officedocument.wordprocessingml.document")
    )
      return fileIcons.word;
    if (fileType.includes("spreadsheet")) return fileIcons.excel;
    if (fileType.includes("image")) return fileIcons.image;
    return fileIcons.unknown;
  };
  const getStatusStyles = (statusId) => {
    switch (statusId) {
      case 1: // Черновик
        return {
          borderColor: "rgba(158, 158, 158, 0.5)",
          backgroundColor: "rgba(158, 158, 158, 0.1)",
          color: "rgba(66, 66, 66, 0.8)",
        };
      case 2: // На проверке у сотрудника
        return {
          borderColor: "rgba(33, 150, 243, 0.5)",
          backgroundColor: "rgba(33, 150, 243, 0.1)",
          color: "rgba(0, 81, 148, 0.8)",
        };
      case 3: // Контракт на согласовании в банке
        return {
          borderColor: "rgba(0, 121, 191, 0.5)",
          backgroundColor: "rgba(0, 121, 191, 0.1)",
          color: "rgba(0, 51, 102, 0.8)",
        };
      case 4: // Агентский договор на подписании
        return {
          borderColor: "rgba(255, 193, 7, 0.5)",
          backgroundColor: "rgba(255, 193, 7, 0.1)",
          color: "rgba(255, 152, 0, 0.8)",
        };
      case 5: // Агентский договор подписан
        return {
          borderColor: "rgba(76, 175, 80, 0.5)",
          backgroundColor: "rgba(76, 175, 80, 0.1)",
          color: "rgba(56, 142, 60, 0.8)",
        };
      case 6: // Отмена
        return {
          borderColor: "rgba(244, 67, 54, 0.5)",
          backgroundColor: "rgba(244, 67, 54, 0.1)",
          color: "rgba(198, 40, 40, 0.8)",
        };
      case 7: // Контракт согласован
        return {
          borderColor: "rgba(63, 81, 181, 0.5)",
          backgroundColor: "rgba(63, 81, 181, 0.1)",
          color: "rgba(48, 63, 159, 0.8)",
        };
      default:
        return null;
    }
  };

  return (
    <div className={styles.body}>
      <h2>{text}</h2>
      {options.map((item, index) => (
        <React.Fragment key={index}>
          <div className={styles.element}>
            <div className={styles.name}>
              <span>{item.label}</span>
            </div>
            {item.type === "Chip" && (
              <div className={styles.field}>
                {item.name === "Status" &&
                  contractStatus &&
                  (() => {
                    const styles = getStatusStyles(contractStatus.id);
                    if (!styles) return null;
                    return (
                      <ReusableChip
                        borderColor={styles.borderColor}
                        backgroundColor={styles.backgroundColor}
                        color={styles.color}
                        label={contractStatus.status_title}
                        minHeight="45px"
                        minWidth="100px"
                      />
                    );
                  })()}
                {item.name === "is_vbk" && (
                  <ReusableChip
                    borderColor={
                      contract.is_vbk
                        ? "rgba(76, 175, 80, 0.5)"
                        : "rgba(33, 150, 243, 0.5)"
                    }
                    backgroundColor={
                      contract.is_vbk
                        ? "rgba(76, 175, 80, 0.1)"
                        : "rgba(33, 150, 243, 0.1)"
                    }
                    color={
                      contract.is_vbk
                        ? "rgba(28, 107, 31, 0.8)"
                        : "rgba(0, 81, 148, 0.8)"
                    }
                    label={contract.is_vbk ? "Да" : "Нет"}
                    minHeight="45px"
                    minWidth="100px"
                  />
                )}
              </div>
            )}
            {item.name === "TextCompany" && (
              <div className={styles.field}>{company.full_name}</div>
            )}
            {item.name === "TextManagerComment" && (
              <div className={styles.field}>{contract.comment || "---"}</div>
            )}
            {item.name === "TextContractNumber" && (
              <div className={styles.field}>{contract.contract_number}</div>
            )}
            {item.type === "TextField" && (
              <div className={styles.field}>
                <TextField
                  label="Введите свой комментарий"
                  sx={{
                    width: "100%",
                    "& .MuiInputLabel-root": {
                      color: "#757575",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#757575",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#757575",
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      color: "#757575",
                    },
                  }}
                  value={clientComment}
                  // InputLabelProps={{
                  //   shrink: !!contract.comment_client,
                  // }}
                  onChange={handleChangeComment}
                />
              </div>
            )}
            {item.type === "Card" && (
              <div className={styles.filesContainer}>
                {contract.files?.length > 0
                  ? contract.files.map((item, index) => (
                      <div className={styles.card} key={index}>
                        <div className={styles.cardContent}>
                          <div className={styles.icon}>
                            {getFileIcon(item.file_type)}
                          </div>
                          <span className={styles.text}>{item.file_name}</span>
                        </div>
                        <button className={styles.downloadButton}>
                          <a
                            href={`http://45.131.42.80${item.contr_file}`}
                            download={item.file_name}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.downloadLink}
                          >
                            <FaCloudDownloadAlt size={20} />
                          </a>
                        </button>
                      </div>
                    ))
                  : "Нет загруженных файлов"}
              </div>
            )}
            {item.type === "Button" && (
              <div className={styles.field}>
                <button
                  className={styles.downloadButton}
                  onClick={() => triggerFileInput(item.name)}
                >
                  {fileLoading ? (
                    <DotsSpinner />
                  ) : (
                    <>
                      <span>{item.text}</span>
                      <GrAttachment size={20} />
                    </>
                  )}
                </button>
                <input
                  id={item.name}
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
              </div>
            )}
          </div>
          {index < options.length - 1 && <div className={styles.line} />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default InfoComponent;
